<template>
  <div class="listModel">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper"
           @keydown.enter.prevent="pageNum == 1 ? searchData() : pageNum=1">
        <el-form :inline="true"
                 label-position="right"
                 label-width="100px"
                 :model="formInline"
                 class="demo-form-inline"
                 size="medium">
          <slot name="searchCriteria">
            <!-- 搜索条件 -->
          </slot>
          <el-button size="medium"
                     v-for="opt in searchOpt"
                     :key="opt.id"
                     :type="opt.type"
                     :icon="opt.icon"
                     @click.native="opt.icon == 'search' ? ((opt.func&&opt.func()) || search()) : opt.func()"
                     :loading="opt.loading ? loading : false"
                     v-show='opt.show'>{{opt.label}}</el-button>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table v-loading="loading"
                  border
                  :data="tableData"
                  style="width: 100%"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection"
                           width="55"
                           align='center'
                           v-if="selectOpt.length"></el-table-column>
          <el-table-column type="index"
                           :index="indexMethod"
                           :label="$t('list.index')"
                           align='center'
                           width="70"></el-table-column>
          <el-table-column :prop="item.prop"
                           align='center'
                           :label="item.label"
                           :width="item.width"
                           v-for="item in tableCols"
                           :key="item.prop"
                           :formatter="item.formatter"></el-table-column>4
          <el-table-column :label="tableOpt.label"
                           align='center'
                           :width="tableOpt.width||100"
                           v-if="	tableOpt.operations[0].show || tableOpt.operations[1].show">
            <template slot-scope="scope">
              <!-- <el-button type="text"  v-for="opt in tableOpt.operations" :key="opt.label" @click="opt.func(scope)">{{opt.label}}</el-button> -->
              <el-dropdown style="color: #20a0ff; cursor: pointer;"
                           @command="handleCommand($event, scope)"
                           v-if="tableOpt.operations.length>=2">
                <el-button type="primary"
                           size="small"
                           :disabled='!tableOpt.show'>
                  操作
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-show='opt.show'
                                    v-for="opt in tableOpt.operations"
                                    :command="opt.label"
                                    :key="opt.label">{{opt.label}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-button v-if="selectOpt.length"
                     plain
                     class="left"
                     v-for="opt in selectOpt"
                     :key="opt.id"
                     :type="opt.type"
                     :icon="opt.icon"
                     @click.native="opt.func"
                     size="mini"
                     round>{{opt.label}}</el-button>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="pageNum"
                         :page-size="pageSize"
                         layout="total, prev, pager, next, jumper"
                         :total="total"
                         v-if="total != 0">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'listModel',
  data() {
    return {
    }
  },
  methods: {
    indexMethod(index) {
      return (index + 1) + (this.pageNum - 1) * this.pageSize;
    },
    handleSelectionChange(val) {
      this.$emit('select', val);
    },
    handleCurrentChange(val) {
      this.$emit('update:pageNum', val);
      this.searchData();
    },
    handleCommand(command, scope) {
      let operations = this.tableOpt.operations;
      for (let i in operations) {
        if (operations[i].label == command) {
          operations[i].func(scope);
          return;
        }
      }
    },
    search() {
      this.pageNum == 1 ? this.searchData() : this.pageNum = 1
    }
  },
  props: {
    total: { // 总数
      type: Number,
      default: 0
    },
    pageSize: { // 条数
      type: Number,
      default: 15
    },
    pageNum: { // 当前页码
      type: Number,
      default: 1
    },
    searchOpt: { // 搜索区域按钮
      type: Array,
      default() {
        return [{
          type: 'primary', // primary / success / warning / danger / info / text
          icon: 'search',
          label: '查询',
          loading: true
        }]
      }
    },
    loading: { // loading动画
      type: Boolean,
      default: false
    },
    tableCols: { // 列表表头
      type: Array,
      default: function () { return [] }
    },
    tableData: { // 列表数据
      type: Array,
      default: []
    },
    formInline: { // 搜索条件
      type: Object,
      default: {}
    },
    searchData: { // 查询函数
      type: Function
    },
    tableOpt: { // 列表操作按钮
      type: Object,
      default: null
    },
    selectOpt: { // 页脚操作按钮
      type: Array,
      default() {
        return [];
      }
    }
  },
  updated() {
  },
  mounted() {
  },
  created() {
    // this.searchData();
  },
  computed: {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
